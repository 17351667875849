import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Typography } from "@material-ui/core"
import Layout from "../components/layout/layout-home"
import TitledContentWrapper from "../components/layout/titled-content-wrapper"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  content: {
    paddingBottom: "50px",
    maxWidth: "1000px",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
}))

const TermsAndConditionsPage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <TitledContentWrapper title="Application tips">
        <Typography variant="body1" className={classes.content}>
          <p>
            <strong>Dear Applicants,</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              We are regularly asked questions on the best ways to apply for a
              job at DIGIC. We would like to share some tips that may help you
              get hired!
            </span>
          </p>
          <p>
            <br />
            <br />
          </p>
          <p>
            <strong>PRIORITIES:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              When you apply for an artistic position, the strength of your
              showreel/portfolio is the most important thing.
            </span>
          </p>
          <p>
            <span>
              Presenting your previous work in an efficient way will hugely
              increase your chances of standing out of the crowd and being
              noticed by the Hiring Managers!&nbsp;&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              The world of 3D animation encompasses a wide array of styles and
              formats. When our Hiring Managers decide who to invite for an
              interview, they look for the candidates whose portfolios contain
              work similar to what our colleagues usually produce.
            </span>
          </p>
          <p>
            <span>
              Take a look at DIGIC&rsquo;s past work! Since you will probably
              need to create artwork in a similar vein, try to focus on the
              pieces of your previous work which prove that you are up for the
              challenge!
            </span>
          </p>
          <p>
            <span>
              It may be useful to do a quick research on the internet to find
              some tips and advice on how to put a portfolio together. There is
              plenty of material available!
            </span>
          </p>
          <p>
            <br />
            <br />
          </p>
          <p>
            <strong>APPLY ON THE WEBSITE!&nbsp;</strong>
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <span>
                Many applicants do not trust that their application will be
                processed through the website. We assure you that applying on
                our job site is the most efficient way to turn in your
                application - and it is also the only way we can guarantee that
                your personal data will be handled in a way that is compliant
                with all data protection laws and regulations.{" "}
              </span>
              <span>
                <br />
              </span>
              <span>
                Please make sure that you apply on the website! We guarantee
                that your profile will be evaluated by the relevant personnel!
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                If you believe that you have the abilities to prove yourself in
                more than one job, you should apply for all of them - they may
                be evaluated by different Hiring Managers. However, please make
                sure that you only apply for jobs that require similar skills to
                yours!{" "}
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                You can maximize your chances of success if you apply for an
                existing, open job listing rather than the general, &ldquo;SEND
                CV&rdquo; page. Sending a general application only makes sense
                if you absolutely cannot find a relevant position among our
                current listings. If you apply for a particular job, applying on
                the &ldquo;
              </span>
              <a
                style={{ color: "#f26522" }}
                target="_blank"
                href="https://career.digicpictures.com/jobs/cv-feltoltes?lang=EN"
              >
                <span>SEND CV</span>
              </a>
              <span>&rdquo; page will not increase your chances at all.</span>
            </li>
          </ul>
          <p>
            <br />
            <br />
          </p>
          <p>
            <strong>TIPS FOR WRITING YOUR CV</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              You can find thousands of websites offering tips and CV templates,
              but here is&nbsp; some advice&nbsp; that you should keep in mind:
            </span>
            <span>
              <br />
              <br />
            </span>
          </p>
          <ul>
            <li>
              <span>
                Please read the job ad thoroughly! While writing your CV, try to
                focus on showing us that you fulfill all requirements of that
                particular job!
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                Your CV should include all information about you that is
                relevant for the particular positions you are applying for. For
                example: softwares/tools you are proficient with, languages you
                speak, brief list of responsibilities in your previous jobs,
                etc.{" "}
              </span>
              <span>
                <br />
              </span>
              <span>
                Please think about how detailed the information you share with
                us should be, and
              </span>
              <span>
                <br />
              </span>
              <span>
                concentrate on the most relevant parts of your skills and
                experiences!
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                If you worked previously in a position similar to the one you
                are applying for, make sure you describe the details of your
                responsibilities there in a brief but efficient way!{" "}
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                While listing and detailing your previous work experiences,
                please also briefly mention all jobs that may not be relevant to
                your application - It could still be useful for us to know about
                them!
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                If there are some bits of information you consider relevant, but
                you cannot fit in your CV, feel free to include a short summary
                of them in your application.
              </span>
              <span>
                <br />
              </span>
              <span>
                You do not need to stick to any regular cover letter format. You
                can either upload these notes as a separate file, or simply copy
                the text to the &ldquo;OTHER NOTES&rdquo; field of the
                application form.
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                Formatting your CV nicely may help your application a lot! We
                will NOT reject your application based on the design of your CV,
                but giving Hiring Managers a good first impression certainly
                helps!
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                Please send all your documents (CV and other files) in PDF
                format, if possible!
              </span>
            </li>
          </ul>
          <p>
            <br />
            <br />
          </p>
          <p>
            <strong>TIPS FOR COMPILING YOUR PORTFOLIO/SHOWREEL</strong>
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <span>
                The great majority of DIGIC&rsquo;s movies fall into the
                realistic Sci-fi/Fantasy genre. Your portfolio/showreel should
                convince us that you can produce high quality artwork in this
                vein. Make sure you present the full range of what you can
                create within this style!
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                Focus on the parts of your previous work that are relevant for
                the position you are applying for! Make sure that your
                portfolio/showreel highlights the artwork that is closest to
                DIGIC&rsquo;s style!
              </span>
              <span>
                <br />
              </span>
              <span>
                It may be a plus to show variety, but other styles (cartoony,
                etc.) should not dominate your profile!
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                We suggest that you only show us your most recent material that
                fits the above criteria! Including past work that may show
                lesser skills than what you are currently capable of will
                probably lower the chances of getting hired! It is better to
                simply exclude them.
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                If your showreel includes material created in collaboration with
                other artists, please describe what your responsibilities&nbsp;
                were exactly in the process! You can either do this by adding
                subtitles to the showreel itself, or turning in a breakdown as a
                separate pdf file!
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                Showreels should be sent by uploading them to Youtube, Vimeo or
                other similar sites and copying the link to the
                &ldquo;SHOWREEL/PORTFOLIO URL&rdquo; field of the application
                form!
              </span>
              <span>
                <br />
              </span>
              <span>
                If the video is protected with a password, please share the
                password with us in the &ldquo;OTHER NOTES&rdquo; field!
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                If you have a portfolio of still images, you can upload them to
                your candidate profile as a pdf file, but - due to size
                limitations - it may be a better idea to put an online portfolio
                together (on Artstation, Behance, etc.) and simply give us a
                link to the gallery!
              </span>
              <span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span>
                Naturally, we also offer other positions that do not require a
                portfolio. When applying for a job in production coordination,
                project management, etc., we do not expect you to send us a
                portfolio.
              </span>
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <strong>OTHER</strong>
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <span>
                Please make sure that you give us an idea of your expected net
                salary in the relevant field of the application form.
              </span>
              <span>
                <br />
              </span>
              <span>
                Do not worry: if your application seems relevant, but your
                request does not fit the salary range we can offer, we will
                still get in touch with you, and inform you of the
                possibilities!
              </span>
            </li>
          </ul>
          <p>
            <br />
            <br />
          </p>
          <p>
            <strong>
              WE ARE LOOKING FORWARD TO RECEIVING YOUR APPLICATION!
            </strong>
          </p>
        </Typography>
      </TitledContentWrapper>
    </Layout>
  )
}

export default TermsAndConditionsPage
